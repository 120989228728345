




import {  Vue } from 'vue-property-decorator';

export default Vue.extend({
    name: "DateView",
    props: {
        value: String,
    },
    data() {
        return {
          formattedDate: '',
        }
    },
    created() {
      if (this.value!=null && this.value!="") {
        const datstr= new Date(this.value).toLocaleDateString("de-AT", {day: "2-digit", month: "2-digit", year: "numeric"});
          this.formattedDate= datstr;
      }
    }
});
