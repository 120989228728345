




































































import { Vue } from 'vue-property-decorator';
import { roomService, healthdataService, clientService, reportService } from '@/_services';
import { settings }  from '@/variables';
import EventBus from '@/variables'
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import { Reservation, RoomElement, ReportItem, CheckIn, ClientHealthData} from '@/types';
import DateView from '@/components/DateView.vue';

Vue.use(BootstrapVueIcons);
export default Vue.extend({
  name: "App",
  data() {
    return {
      vorschlagNr: 0,
      tabellenNr: 0,
      clients: [],
      rooms: [] as RoomElement[],
      selectedCheckIn: {},
      today: new Date(),
      eventHub: EventBus,
      selectedRoom: "",
      numberOfDays: 10,
      daysBeforeNow: 3,
      maxReportNumber: 0,
      reports: [] as ReportItem[],
      reportFields: [
        { key: "number", label: "ReportNr", filter: "true"}, 
        { key: "reportDate", label: "Datum", filter: "true"}, 
        { key: "sentTime", label: "Gesendet", filter: "true"}, 
        { key: "actions", label: "Download", filter: "false"}, 
      ],
      fields: [
        { key: "type", label: "Typ", filter: "true" },
        { key: "roomNumber", label: "Zimmer", filter: "true"}, 
        { key: "firstName", label: "Vorname", filter: "true"}, 
        { key: "lastName", label: "Nachname", sortable: "true", },
        { key: "artistName", label: "Künstlername", sortable: "true", },
        { key: "address", label: "Adresse", filter: "true" },
        { key: "zipcode", label: "PLZ", filter: "true" },
        { key: "city", label: "Ort", filter: "true" },
        { key: "birthDay", label: "Geburtstag", filter: "true" },
        { key: "checkInDate", label: "Checkin" },
        { key: "checkInReported", label: "gemeldet" },
        { key: "checkOutDate", label: "Checkout" },
        
      ],
      detailFields: [
        { key: "contactPhone", label: "Telefon" },
        { key: "contactEmail", label: "Email" },
        { key: "healthdata_blut", label: "Blut" },
        { key: "healthdata_abstrich", label: "Abstrich" },
        { key: "healthdata_tbc", label: "TBC Röntgen" },
        { key: "placeOfBirth", label: "Geburtsort", filter: "true" },
        { key: "typeOfStay", label: "Aufenthaltsart", filter: "true" },
        { key: "documentType", label: "Dok.typ" },
        { key: "documentNumber", label: "Passnumer" },
        { key: "documentIssuer", label: "Aussteller" },
        { key: "documentIssueDate", label: "Ausstellungsdatum" },
        { key: "documentValidity", label: "Gültig bis" },
        { key: "healthBookIssuer", label: "Gesundheitsbuch" },
        { key: "healthBookCreated", label: "Ausstellung Gesundheitsbuch" },
      ],
      checkInData: [] as CheckIn[],
      tempReports: [] as ReportItem[],
      suggestedReport: [] as ReportItem[],
      setting: settings,
      infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
          data: {},
          isNew: false
        }
    };
  },
  created():void {
    this.loadRooms();
    this.loadReports();
    this.loadCheckIns();
    this.reCreate();
  },
  mounted():void {
    this.eventHub.$on('reservationInfo', (reservation: Reservation) => {
      (this as any).selectedReservation= reservation;
      this.$root.$emit('bv::show::modal', "reservationInfo");
      (this as any).loadClientImage();
      (this as any).items.forEach((roomElem: RoomElement) => {
        if (roomElem._key==reservation.room) {
          this.selectedRoom= roomElem.name;
        }
      });
    });
    this.eventHub.$on("updateTables", () => {
      this.vorschlagNr++;
    });
  },
  methods: {
    asArray(obj: any) {
      let result= [];
      result.push(obj);
      console.log("array: " + JSON.stringify(result));
      return result;
    },
    loadRooms() {
      roomService.getAll().then((result: any) => {
        console.log("rooms received");
        this.rooms= result;
      });
    },
    openReport(item: any, type: string) {
      const w= window.open(this.setting.printApi+ "/Report?id="+item._key + "&format=" + type, "_blank");
      if (w) {
        w.focus();
      }
    },
    loadReports() {
      reportService.getReports().then((result: any) => {
        console.log("reports received");
        this.reports = result;
        for (const r of this.reports) {
          if (r.number!=null && r.number>this.maxReportNumber) {
            this.maxReportNumber= r.number;
          }
        }
        this.tabellenNr++;
      });
    },
    reCreate():void {
      if (this.checkInData.length==0 ) { // || this.invoices.length==0) {
        // console.log("no data loaded, waiting for all data");
        return;
      }
      this.suggestedReport= [];
      this.checkInData.forEach((checkin: CheckIn) => {
          let customerKey= checkin._from.split("/")[1];
          let inout = checkin.checkOutDate!=null?'OUT':'IN';
          clientService.getById(customerKey).then((customer: any) => {
            let newReport = {
                type: inout,
                roomNumber: ""+this.getRoomNumber(checkin._to),
                checkInKey: checkin._key,
                firstName: customer.firstName,
                lastName: customer.lastName,
                artistName: customer.artistName,
                address: customer.address,
                zipcode: "" + customer.zipcode,
                city: customer.city,
                birthDay: customer.birthDay,
                placeOfBirth: customer.placeOfBirth,
                typeOfStay: customer.typeOfStay,
                documentType: customer.documentType,
                documentNumber: customer.documentNumber,
                documentIssuer: customer.documentIssuer,
                documentIssueDate: customer.documentIssueDate,
                documentValidity: customer.documentValidity,
                contactPhone: customer.contactPhone,
                contactEmail: customer.contactEmail,
                healthdata_blut: '',
                healthdata_abstrich: '',
                healthdata_tbc: '',
                checkInDate: checkin.checkInDate,
                checkInReported: checkin.checkInReported,
                checkOutDate: checkin.checkOutDate,
                healthBookIssuer: customer.healthBookIssuer,
                healthBookCreated: customer.healthBookCreated 
            } as ReportItem;
            this.loadHealthData(customerKey, newReport);
            this.suggestedReport.push(newReport);
          });

      });
    },
    loadHealthData(clientKey: any, newReport: ReportItem) {
        healthdataService.getFromClient(clientKey).then((data: ClientHealthData[]) => {
            data.map((d: ClientHealthData) => {
              console.log("recieved healthdata for customer " + clientKey + ", " + JSON.stringify(d));
                let ckDatStr= "01.01.1970";
                if (d.checkedAt!=null) {
                  let ckDat= new Date(d.checkedAt);
                  ckDatStr= "" + ckDat.getDate() + "." + (ckDat.getMonth()+1) + "." + ckDat.getFullYear();
                }
                if (d.name=="Blut") {
                  newReport.healthdata_blut= ckDatStr;
                } else if (d.name=="Abstrich") {
                  newReport.healthdata_abstrich = ckDatStr;
                } else if (d.name=="TBC Röntgen") {
                  newReport.healthdata_tbc = ckDatStr;
                }
                
            });
        });
    },
    recalcItem(itemArr: any) {
      console.log("itemArr" + JSON.stringify(itemArr));
      itemArr.itemPrice = itemArr.amount*itemArr.singlePrice;
      // this.reCalc();
    },
    reloadAllData() {
      this.checkInData = [];
      this.suggestedReport= [];
      // this.newInvoices = [];
      this.loadReports();
      this.loadRooms();
      this.loadCheckIns();
      this.reCreate();
    },
    getRoomNumber(roomKey: string) {
        let rkey= roomKey.split("/")[1];
        console.log("finding room " + rkey);
        for (let r in this.rooms ) {
            let room= this.rooms[r];
            console.log("testing " + JSON.stringify(room));
            if (room._key==rkey) {
                console.log("found " + room.name);
                return room.name;
            }
        }
        return "Extra";
    },
    saveReport(): void {
      console.log("storing new Reports");
      let newReport = {
        number: this.maxReportNumber+1,
        reportDate: new Date(),
        authorizedBy: JSON.parse(""+localStorage.getItem("user")).name,
        checkInOut: this.suggestedReport
      };
      for (const io of newReport.checkInOut) {
        delete io.checkInReported;
      }
      console.log("saving report: " + JSON.stringify(newReport));
      reportService.insertReport(newReport).then((result: any) => {
        if (result.error) {
          alert(result.errorMessage);
        } else {
          this.reloadAllData();
        }
      })
    },
   loadCheckIns() {
      roomService.getOpenCheckIns(new Date(), 27).then((result: any) => {
        console.log("checkins received");
        this.checkInData= result;
        this.reCreate();
      });
    },
  },
  components: {
    // EditInvoice,
    DateView,
  }
  
});
